import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "WebLayout" */ "@/layouts/WebLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false,
          title: "Inicio- Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/actividades",
        name: "Actividades",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Actividades/GeneralView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Actividades - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/actividades/categoria/:nombre",
        name: "CategoriaActividad",
        component: () =>
          import(
            /* webpackChunkName: "CategoriaActividad" */ "@/views/Actividades/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias"
        }
      },
      {
        path: "/actividades/publicacion/:nombre",
        name: "PublicacionAct",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Actividades/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Actividad"
        }
      },
      {
        path: "/contacto",
        name: "Contacto",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Contacto/ContactoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Contacto"
        }
      },
      {
        path: "/videos-municipales",
        name: "videosMunicipales",
        component: () =>
          import(
            /* webpackChunkName: "videosmunicipales" */ "@/views/VideosMunicipales/VideosMunicipalesView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Videos Municipales - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/salud",
        name: "Salud",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Salud/SaludView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Salud - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/educacion",
        name: "Educacion",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Educacion/EducacionView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Educación - Ilustre Municipalidad de Juan Fernández"
        }
      },
      // {
      //   path: "/documentacion",
      //   name: "Documentos",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "documentacion" */ "@/views/Documentacion/DocumentacionView.vue"
      //     ),
      //   meta: {
      //     requiresAuth: false,
      //     title: "Documentos - Ilustre Municipalidad de Juan Fernández"
      //   }
      // },
      {
        path: "/documentos/categoria/:nombre",
        name: "Documentos",
        component: () =>
          import(
          /* webpackChunkName: "ambiente-laboral" */ "@/views/Documentacion/DocumentacionView.vue"
      ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/turismo",
        name: "Turismo",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Turismo/TurismoView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Turismo - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Noticias/NoticiasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Noticias - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/medio-ambiente",
        name: "medio-ambiente",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/MedioAmbiente/MedioAmbienteView.vue"
          ),
        meta: {
          title: "Medio Ambiente - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/noticias/publicacion/:nombre",
        name: "Publicacion",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Noticias/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia"
        }
      },
      {
        path: "/contenido/noticias/preview/:nombre",
        name: "PublicacionPreview",
        component: () =>
          import(
            /* webpackChunkName: "PublicacionPreview" */ "@/views/Noticias/PublicacionPreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia Preview"
        }
      },
      {
        path: "/noticias/categoria/:nombre",
        name: "Categoria",
        component: () =>
          import(
            /* webpackChunkName: "Categoria" */ "@/views/Noticias/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias"
        }
      },
      {
        path: "/municipio/concejales",
        name: "Concejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcejoView.vue"
          ),
        meta: {
          title: "Concejales - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/:nombre",
        name: "Pagina",
        component: () =>
          import(
            /* webpackChunkName: "Pagina" */ "@/views/Pagina/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/contenido/pagina/preview/:nombre",
        name: "PaginaPreview",
        component: () =>
          import(
            /* webpackChunkName: "PaginaPreview" */ "@/views/Pagina/PreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Preview Página"
        }
      },
      {
        path: "/formulario/traslados",
        name: "Traslados",
        component: () =>
          import(
            /* webpackChunkName: "Traslados" */ "@/views/Traslados/TrasladosView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Pagos y Servicios- Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/municipio/servicios",
        name: "Servicios",
        component: () =>
          import(
            /* webpackChunkName: "Servicios" */ "@/views/Servicios/ServiciosView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Pagos y Servicios- Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/formulario/contacto/:nombre/:id",
        name: "FormularioContacto",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/FormularioGeneralView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title:
            "Formulario de Contacto - Ilustre Municipalidad de Juan Fernández"
        }
      },

      {
        path: "/reserva/:nombre",
        name: "Reserva",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/ReservaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/reserva/ticket/:codigo",
        name: "ReservaTicket",
        component: () =>
          import(
            /* webpackChunkName: "reserva" */ "@/views/Reserva/RevisarReservaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/reserva/:nombre/anular",
        name: "AnularReserva",
        component: () =>
          import(
            /* webpackChunkName: "AnularReserva" */ "@/views/Reserva/AnularReservaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/municipio/reserva",
        name: "ListaReservas",
        component: () =>
          import(
            /* webpackChunkName: "ListaReservas" */ "@/views/Reserva/ListaReservasView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/formulario/medio-ambiente",
        name: "MedioAmbiente",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioInscripcionView.vue"
          ),
        meta: {
          title:
            "Inscripción Seminario Día Mundial del Medio Ambiente - Ilustre Municipalidad de Juan Fernández"
        }
      },

      {
        path: "/municipio/ofertas",
        name: "OfertasOmil",
        component: () =>
          import(
            /* webpackChunkName: "oferta" */ "@/views/Web/OfertasOmilView.vue"
          ),
        meta: {
          title: "Bolsa de Empleo - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/encuesta/:nombre",
        name: "Encuesta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Encuesta/EncuestaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/municipio/oficinas-y-telefonos-municipales",
        name: "Telefonos",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Web/TelefonosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title:
            "Oficinas y Teléfonos - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/municipio/concursos-publicos",
        name: "ConcursosPublicos",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcursosPublicosView.vue"
          ),
        meta: {
          title: "ConcursosPublicos - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/municipio/actas-concejo",
        name: "ActasConcejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ActasConcejoView.vue"
          ),
        meta: {
          title: "Actas de Concejo - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/municipio/ordenanzas",
        name: "Ordenanzas",
        component: () =>
          import(
            /* webpackChunkName: "ordenanzas" */ "@/views/Municipalidad/OrdenanzasView.vue"
          ),
        meta: {
          title: "Ordenanzas - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/municipio/cosoc",
        name: "Cosoc",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Municipalidad/CosocView.vue"
          ),
        meta: {
          title:
            "Consejo Comunal de Organizaciones de la Sociedad Civil - Ilustre Municipalidad de Juan Fernández"
        }
      },
      

      {
        path: "/resultados/navidad",
        name: "ResultadosNavidad",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Resultados/ResultadosNavidadView.vue"
          ),
        meta: {
          title:
            "Resultados Feria Navideña 2022 - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/formulario/postulacion",
        name: "Postulacion",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioPostulacionView.vue"
          ),
        meta: {
          title:
            "Trabaja con Nosotros - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/municipio/galerias",
        name: "Galerias",
        component: () =>
          import(
            /* webpackChunkName: "galerias" */ "@/views/Galeria/GaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galerías - Ilustre Municipalidad de Juan Fernández"
        }
      },
      {
        path: "/galeria/:nombre",
        name: "DetalleGaleria",
        component: () =>
          import(
            /* webpackChunkName: "detallegaleria" */ "@/views/Galeria/DetalleGaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galeria - Ilustre Municipalidad de Juan Fernández"
        }
      }
      // { path: '/:pathMatch(.*)', redirect: { name: 'Home' } }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
